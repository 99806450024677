<template>
  <div class="c-app modal-vue add-miners">
    <CWrapper class="bg-bg-gray pt-2">
      <CForm>
        <CRow class="add-miners__wrap">
          <CCol md="8" class="mb-4">
            <div class="c-title">{{ $t("Add multiple miner") }}</div>
          </CCol>
          <CCol md="4" class="mb-4">
            <router-link
              to="/AddMiner"
              class="button-blue text-uppercase d-flex justify-content-center align-items-center text-decoration-none"
              >{{ $t("Add single") }}</router-link
            >
          </CCol>
          <!-- <CCol md="8">
            <p class="mb-2">
              <label for="first_name">Title</label>
              <CInput class="" v-model="formFields.name" />
            </p>
          </CCol> -->
          <CCol md="8">
            <p class="mb-2">
              <!-- <label for="first_name">Сrawler type</label> -->
              <CSelect
                label="Сrawler type"
                name="crawler"
                @update:value="updateValue"
                value=""
                :options="[
                  '',
                  'TestMiner',
                  'Antminer',
                  'Avalon',
                  'InnosiliconApi',
                  'Whatsminer',
                  'Innosilicon',
                ]"
              />
            </p>
          </CCol>
          <CCol md="8">
            <p class="mb-2">
              <label for="first_name">Expected Hashrate (Gh/s)</label>
              <CInput class="" v-model="formFields.exp_hashrate" />
            </p>
          </CCol>
          <!-- <CCol md="8">
            <p class="mb-2">
              <label for="first_name">MAC</label>
              <CInput class="" readonly v-model="formFields.mac_address" />
            </p>
          </CCol> -->
          <CCol md="4">
            <p class="mb-2">
              <label for="first_name">Power consumption</label>
              <CInput class="" v-model="formFields.exp_powerrate" />
            </p>
          </CCol>
          <CCol md="12">
            <div class="d-flex align-items-center">
              <CCol md="8" class="pl-0 d-flex justify-content-between flex-wrap">
                <p class="mb-2" style="width: 48%">
                  <label>IP start</label>
                  <CInput class="" v-model="formFields.ip_address" />
                </p>
                <p class="mb-2" style="width: 48%">
                  <label>IP end</label>
                  <CInput class="" v-model="formFields.ip_address2" />
                </p>
              </CCol>
              <CCol md="4" class="pr-0 mt-4">
                <CButton
                  class="button-blue text-uppercase"
                  :disabled="checkAllFields()"
                  @click="checkConnection()"
                  v-if="!check.connection"
                >
                  {{ $t("check connection") }}</CButton
                >
                <div>
                  <CSpinner color="main_color" v-if="check.connection" />
                </div>
                <span class="font-sm text-dark mt-1" v-if="!choosedCrawler">
                  {{ $t("First select a crawler") }}</span
                >
              </CCol>
            </div>
          </CCol>
          <!-- <CCol md="4">
            <p class="mb-2">
              <label for="first_name">Shelf</label>
              <CInput class="" readonly v-model="formFields.shelf" />
            </p>
          </CCol> -->
          <!-- <CCol md="4">
            <p class="mb-2">
              <label for="first_name">Position</label>
              <CInput class="" readonly v-model="formFields.position" />
            </p>
          </CCol> -->
          <CCol md="8">
            <p class="mb-2">
              <!-- <label for="first_name">Model</label> -->
              <!-- <CInput class="" id="location" /> -->
              <CSelect
                label="Model"
                @update:value="updateValue"
                name="model"
                value=""
                :options="[
                  '',
                  'test miner',
                  'antminer-s19I -885',
                  'innosilicon t3+',
                  'whatsminer m21s',
                  'whatsminer m31s',
                  'whatsminer m32s',
                  'antminer-s9',
                  'antminer-s19I -920',
                  'avalon 1066',
                  'avalon 852',
                ]"
              />
            </p>
          </CCol>
          <CCol md="8">
            <p class="mb-2">
              <!-- <label for="first_name">Owner</label> -->
              <!-- <CInput class="" id="location" /> -->
              <CSelect
                label="Owner"
                name="user_id"
                value=""
                @update:value="updateValue"
                :options="[
                  '',
                  ...getters.owners.map((e) => {
                    return { ...e, value: e.email };
                  }),
                ]"
              />
            </p>
          </CCol>
          <CCol md="8">
            <p class="mb-2">
              <!-- <label for="first_name">Pool</label> -->
              <!-- <CSelect
                class=""
                name="pool_id"
                :value="''"
                @update:value="updateValue"
                :options="
                  getters.pools.data
                    ? [
                        '',
                        ...getters.pools.data.map((e) => {
                          return { ...e, value: e.name };
                        }),
                      ]
                    : []
                "
              /> -->
            </p>
          </CCol>
          <!-- <CCol md="4">
            <p class="mb-2">
              <label for="first_name">Section</label>
              <CInput class="" readonly v-model="formFields.section" />
            </p>
          </CCol> -->
          <CCol md="8">
            <p class="mb-2">
              <label for="first_name">Farm</label>
              <CSelect
                class=""
                :value="''"
                name="farm_id"
                @update:value="updateValue"
                :options="getters.farms ? ['', ...getters.farms] : []"
              />
            </p>
          </CCol>
          <CCol md="4">
            <p class="mb-2">
              <label for="first_name">Container</label>
              <CSelect
                class=""
                name="container_id"
                @update:value="updateValue"
                :value="''"
                :options="getters.containers ? ['', ...getters.containers] : []"
              />
            </p>
          </CCol>
          <CCol md="8" class="addMiner mt-0">
            <p class="mb-2 calendar">
              <label for="first_name"> {{ $t("Installation date") }}</label>
              <Datepicker :format="customFormatter" v-model="formFields.created_at" />
            </p>
          </CCol>
          <CCol md="12">
            <CCol md="4" class="pl-0 pr-0">
              <div class="d-flex justify-content-between mt-4">
                <CButton
                  class="button-blue text-uppercase"
                  @click="addNewMiner()"
                  v-if="!check.submitted"
                  :disabled="isAvailableSave"
                >
                  {{ $t("Save") }}</CButton
                >
                <CSpinner color="main_color" v-if="check.submitted" />
              </div>
            </CCol>
          </CCol>
        </CRow>
      </CForm>
    </CWrapper>
    <CModal
      title="List"
      color="lightGray"
      class="text-main_black bg-milk_mix"
      :show.sync="ipModal"
    >
      <div class="farm-list">
        <table>
          <tr class="text-center bg-transparent">
            <th class="bg-transparent text-dark">IP</th>
            <th class="bg-transparent text-dark">Status</th>
          </tr>
          <tr v-for="(obj, i) in addresses.items" :key="i" class="text-center">
            <td>{{ obj.ip_address }}</td>
            <td v-if="obj.loaderStatus != 'undefined'">
              <div v-if="obj.isLoading == 'undefined' || obj.isLoading">
                <CSpinner color="main_color" />
              </div>
              <div v-else>
                <span
                  class="px-2 py-1 bg-success text-white rounded-pill"
                  v-if="obj.loaderStatus"
                  >Successful</span
                >
                <span class="px-2 py-1 bg-error_color text-white rounded-pill" v-else
                  >{{ obj.errorText ? obj.errorText : "Error" }}</span
                >
              </div>
            </td>
          </tr>
        </table>
      </div>
      <div class="text-right mt-2"></div>
      <template #footer="{ item }" class="d-none p-0">
        <span class="d-none" />
      </template>
    </CModal>
    <MinerError
      :isOpened="isOpenedModal"
      @changeOpened="updateOpened"
      :miners="invalidMiner"
      :msg="msg"
    />
  </div>
</template>

<script>
import { storeParent } from "../../api/composition/filtering";
import { computed, reactive, ref } from "@vue/composition-api";
import moment from "moment";
import Datepicker from "vuejs-datepicker";
import MinerError from "../Modals/MinerError";

export default {
  name: "AddMiner",
  components: { Datepicker, MinerError },
  setup(props, { root }) {
    const _store = storeParent(root);
    const { startLoading, stopLoading } = _store.helpers().loaders;
    const isOpenedModal = ref(false);
    const formFields = reactive({
      name: "",
      mac_address: "",
      ip_address: "",
      ip_address2: "",
      shelf: "",
      model: "",
      user_id: "",
      pool_id: "",
      worker_id: "",
      farm_id: "",
      container_id: "",
      section: "",
      position: "",
      crawler: "",
      exp_hashrate: "",
      created_at: new Date(),
      exp_temperature: "79",
      exp_powerrate: "",
      status: "active",
      description: "Miner",
      setting: "{}",
    });

    const minersModels = reactive({
      "test miner": {
        exp_hashrate: 57500,
        exp_powerrate: 3300,
        short: 'test',
      },
      "antminer-s19I -885": {
        exp_hashrate: 57500,
        exp_powerrate: 3300,
        short: '885',
      },
      "innosilicon t3+": {
        exp_hashrate: 57500,
        exp_powerrate: 3300,
        short: 't3+',
      },
      "whatsminer m21s": {
        exp_hashrate: 56000,
        exp_powerrate: 3360,
        short: 'm21s',
      },
      "whatsminer m31s": {
        exp_hashrate: 70000,
        exp_powerrate: 3220,
        short: 'm31s',
      },
      "avalon 1066": {
        exp_hashrate: 48500,
        exp_powerrate: 3250,
        short: '1066',
      },
      "antminer-s19I -920": {
        exp_hashrate: 92000,
        exp_powerrate: 3230,
        short: '920',
      },
      "whatsminer m32s": {
        exp_hashrate: 61000,
        exp_powerrate: 3420,
        short: 'm32s',
      },
      "avalon 852": {
        exp_hashrate: 56000,
        exp_powerrate: 3360,
        short: '852',
      },
      "antminer-s9": {
        exp_hashrate: 56000,
        exp_powerrate: 3360,
        short: 's9',
      },
    });
    const errors = ref("");
    const ipRegex = ref(
      /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/
    );
    const ipModal = ref(false);
    const isAvailableSave = ref(true);

    const msg = ref("");
    const invalidMiner = ref(null);
    const check = reactive({
      connection: false,
      submitted: false,
    });
    const choosedCrawler = ref(false);
    const _dispatch = _store.dispatchComposition();
    const addresses = reactive({
      items: [],
    });

    _dispatch.getFarms();
    _dispatch.getPools();
    _dispatch.getContainers();
    _dispatch.getOwners();

    const getters = computed(() => _store.gettersComposition());

    const checkAllFields = () => {
      const { ip_address, ip_address2, crawler, model } = formFields;
      if (
        !ipRegex.value.test(ip_address) ||
        !ipRegex.value.test(ip_address2) ||
        crawler.length == 0 ||
        model.length == 0
      ) {
        return true;
      }
      //check ip addresses
      const validated = validateIpAddresses([ip_address, ip_address2]);

      if (!validated || typeof validated === "boolean") {
        return true;
      }

      const { start, end } = validated;

      return parseInt(start) > parseInt(end);
    };

    const checkConnection = () => {
      startLoading(check, "connection");
      const { ip_address, ip_address2 } = formFields;
      //check ip addresses
      const validated = validateIpAddresses([ip_address, ip_address2]);

      // check type and validation
      if (!validated || typeof validated === "boolean") {
        stopLoading(check, "connection");
      }
      // open Modal
      ipModal.value = true;
      //get starting and ending point
      const { start, end } = validated;

      // get first 3 numbers from ip
      const splitted = ip_address.split(".");
      const ipAddress = splitted.filter((obj, index) => index !== 3).join(".");
      // clone form Object
      const section = splitted[2];

      formFields.section = section;

      let clonnedObj = { ...formFields };

      addresses.items = [];
      // cycle from to
      for (let x = parseInt(start); x <= parseInt(end); x++) {
        const position = x % 10 != 0 ? x % 10 : 10;
        formFields.position = position;
        const generatedAddress = ipAddress + "." + x;
        clonnedObj = {
          ...clonnedObj,
          ip_address: generatedAddress,
          section: section,
          position: position,
        };

        addresses.items.push(clonnedObj);
      }
      for (let x = 0; x < addresses.items.length; x++) {
        addresses.items[x] = { ...addresses.items[x], isLoading: true };
        _dispatch
          .checkMultipleConnection(addresses.items[x])
          .then((res) => res.data)
          .then((res) => {
            const new_ar = [...addresses.items];
            if (res.is_ok) {
              new_ar[x] = { ...new_ar[x], loaderStatus: true };
              new_ar[x] = { ...new_ar[x], mac_address: res.mac_address };
              formFields.mac_address = res.mac_address;
              isAvailableSave.value = false;
            } else {
              new_ar[x] = { ...new_ar[x], loaderStatus: false, errorText: res.errors ? res.errors[0] : "Error" };
              isAvailableSave.value = true;
            }
            new_ar[x].isLoading = false;
            addresses.items = [...new_ar];

            return new_ar;
          })
          .then(() => {
            stopLoading(check, "connection");
          })
          .catch(() => {
            const new_ = addresses.items;
            new_[x] = { ...new_[x], isLoading: false };
            addresses.items = [...new_];
            stopLoading(check, "connection");
          });
      }
    };

    const validateIpAddresses = (ar) => {
      const points = {};
      for (let x = 0; x < ar.length; x++) {
        const splitter = ar[x].split(".");
        if (splitter.length !== 4) return false;
        points[x ? "end" : "start"] = splitter[3];

        splitter.splice(3, 1);

        points["cache"] = points["cache"]
          ? splitter.toString() === points["cache"]
          : splitter.toString();
      }
      if (!points.cache) return false;

      return points;
    };

    const addNewMiner = () => {
      startLoading(check, "submitted");

      addresses.items = addresses.items.map((obj) => {
        const d = new Date()
        const generateNameDate = d.getFullYear().toString().substring(2, 4) + (d.getMonth().toString().length === 2 ? (d.getMonth()+1) : "0" + d.getMonth() + 1);
        const generateNameIP = obj.ip_address;
        const generatedName = generateNameDate + "-" + generateNameIP + "-" + minersModels[formFields.model].short;
        formFields.name = generatedName;
        const copy = { ...formFields };
        delete copy["ip_address2"];
        delete copy["shelf"];
        delete copy['short'];

        return {
          ...copy,
          ip_address: obj["ip_address"],
          crawler: obj["crawler"],
          mac_address: obj["mac_address"],
          section: obj["section"],
          position: obj["position"],
          created_at: moment(copy["created_at"]).format("YYYY-MM-DD"),
        };
      });

      _dispatch
        .addMultipleMiners({ miners: addresses.items })
        .then(() => {
          stopLoading(check, "submitted");
          root.$router.push("/Miners/1");
        })
        .catch(() => {
          stopLoading(check, "submitted");
        });
    };

    const updateOpened = (e) => {
      isOpenedModal.value = e;
    };

    const updateValue = (value, e) => {
      const id = e.target.selectedOptions[0].dataset.key;
      switch (e.target.name) {
        case "pool_id":
          value = getters.value.pools.data[id].id;
          break;
        case "crawler":
          choosedCrawler.value = value != '';
          break;
        case "farm_id":
          value = getters.value.farms[id - 1].id;
          _dispatch.getContainers(value);
          break;
        case "container_id":
          value = getters.value.containers[id - 1].id;
          break;
        case "user_id":
          value = getters.value.owners[id - 1].id;
          _dispatch.getWorkersById(value);
          break;
        case "model":
          if (minersModels[value]) {
            Object.keys(minersModels[value]).forEach((res) => {
              formFields[res] = minersModels[value][res];
            });
          }
          break;
      }

      formFields[e.target.name] = value;
    };

    const customFormatter = (date) => {
      // custom formatter
      return moment(date).format("DD/MM/YYYY");
    };

    return {
      getters,
      customFormatter,
      addresses,
      formFields,
      updateOpened,
      addNewMiner,
      checkAllFields,
      ipModal,
      msg,
      check,
      updateValue,
      choosedCrawler,
      isAvailableSave,
      invalidMiner,
      checkConnection,
      isOpenedModal,
      errors,
    };
  },
  data() {
    return {
      page: parseInt(this.$route.params.id),
    };
  },
  created() {
    this.curPage();
  },
  methods: {
    curPage() {
      this.$emit("curPage", "AddMiner");
    },
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
