<template>
  <CModal
    color="lightGray"
    class="text-main_black bg-milk_mix error-modal"
    :close-on-backdrop="false"
    :show.sync="openedModal"
    @update:show="updateOpened"
  >
    <h4 class="d-flex align-items-center px-4 mb-3">
      <img class="mr-2" src="@/assets/icons/error-icon.svg" alt="" style="width: 22px" />
      {{ $t("This miner is present in the system") }}
    </h4>
    <p class="px-4 d-block" style="font-size: 16px">
      {{ msg }}
    </p>
    <div
      class="rounded-md border d-flex justify-content-between align-items-center p-2 mt-8"
      v-if="miners"
    >
      <div class="d-flex text">
        <p class="mb-0 ml-1 mr-4 position-relative after after-name">
          {{ miners ? miners.name : "" }}
        </p>
        <p class="mb-0 ml-4 position-relative after after-ip">
          {{ miners ? miners.ip_address : "" }}
        </p>
      </div>
      <router-link
        :to="'/MinerInfo/' + miners.id"
        class="button-white button-mini w-auto mr-1"
      >
        More
      </router-link>
    </div>
    <template #footer="{ item }" class="d-none p-0">
      <span class="d-none" />
    </template>
  </CModal>
</template>
<script>
export default {
  name: "MinerError.vue",
  props: ["isOpened", "miners", "msg"],
  watch: {
    isOpened: {
      handler: function (e) {
        this.openedModal = e;
      },
      deep: true,
    },
  },
  data() {
    return {
      openedModal: false,
    };
  },
  methods: {
    updateOpened(e) {
      this.$emit("changeOpened", e);
    },
  },
};
</script>

<style scoped lang="sass">
.modal-header
  padding: 0 !important

.text .after
  font-size: 16px
  &::after
    position: absolute
    left: 0
    top: -30px
    text-transform: uppercase
    color: #888888
    font-size: 12px

.after-name
  &::after
    content: 'Name'

.after-ip
  &::after
    content: 'Ip'

.mt-8
  margin-top: 45px
</style>
